import React from 'react';
import {graphql, PageProps} from 'gatsby';
import {Layout} from '../components/Layout';
import PageWidth from '../components/PageWidth';
import HeaderImage from '../components/HeaderImage';
import SEO from '../components/SEO';
import {PageWrapper, RichText} from '../styles/page';
import renderRichText from '../utils/rich-text-renderer';
import {getImageFromData, getPageData} from '../utils';
import {SupportQuery} from './__generated__/SupportQuery';

const Support = (props: PageProps<SupportQuery>) => {
  const data = getPageData(props.data);
  const headerImage = getImageFromData(data, 'headerImage');

  return (
    <Layout>
      <SEO
        title={data.pageTitle}
        keywords={data.metaKeywords.split(',')}
        description={data.metaDescription}
      />
      <HeaderImage src={headerImage} />
      <PageWrapper>
        <PageWidth narrow>
          <RichText>{renderRichText(data.pageContent)}</RichText>
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query SupportQuery {
    en: allContentfulAmpifyWebSupport(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          metaKeywords
          metaDescription
          pageTitle
          pageContent {
            raw
          }
          headerImage {
            ...AmpifyHeaderImage
          }
        }
      }
    }
  }
`;

export default Support;
